import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import settingsParams from './settingsParams';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSettingsValues } from '@wix/tpa-settings';
import { Service } from '@wix/ambassador-services-catalog-server/types';
import { ACTION_NAMES, biDefaults, generateWidgetDefaults } from './bi/consts';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './controller-logic/scheduling-fetcher';
import { ServiceType, TimezoneType } from '@wix/bookings-uou-types';
import { initUserMessage } from './controller-logic/init-user-message';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { handleNavigation } from './controller-logic/handle-navigation';
import { sendNotification } from '../../api/notification.api';
import { schedulingLocationViewModelFactory } from '../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import {
  SchedulingTimezoneViewModel,
  schedulingTimezoneViewModelFactory,
} from '../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import { initializeViewModels } from './controller-logic/initialize-view-models';
import { SERVICE_PAGE_NAME } from './constants';
import { getTrackingInfoForServicePageLoads } from '@wix/bookings-analytics-adapter';
import { WidgetConfig } from '../../types/shared-types';
import { CourseAvailabilityDisplay } from '../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { isAnywhereFlow } from '../../utils/anywhere/anywhere';
import { sendNoPaymentNotification } from '../../api/sendNoPaymentNotification';
import {
  bookingsBookItClick,
  bookingsCantBookGroupsMessage,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { shouldRedirectToCalendarPage } from './controller-logic/ooi-migration';

// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
enum ScheduleStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
}

export const WARMUP_DATA_KEY = 'ServicePageConfig_';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const setProps = flowAPI.controllerConfig.setProps;
  // FIXME: Yoshi missing translations issue workaround.
  // https://wix.slack.com/archives/CAL591CDV/p1652941335953659
  try {
    await flowAPI.translations.init();
  } catch {}

  const t = flowAPI.translations.t;
  const { controllerConfig, httpClient, experiments, reportError } = flowAPI;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, settingsParams);
  const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
    controllerConfig.wixCodeApi,
    controllerConfig.platformAPIs,
    controllerConfig.appParams,
    controllerConfig.compId,
    experiments,
  );
  let service: Service | undefined;
  let isEditorX: any;
  let prevSettings = settings;

  return {
    async pageReady() {
      const { platformAPIs, appParams, wixCodeApi } = controllerConfig;

      const isAppReflowGetServiceSlugFixEnable = experiments.enabled(
        'specs.bookings.AppReflowServiceSlug',
      );
      if (shouldRedirectToCalendarPage(controllerConfig.wixCodeApi)) {
        const serviceSlug = await wixSdkAdapter.getServiceSlug(
          SERVICE_PAGE_NAME,
          isAppReflowGetServiceSlugFixEnable,
        );
        wixSdkAdapter.navigateToBookingsCalendarPage(
          serviceSlug,
          undefined,
          experiments.enabled('specs.bookings.RouterPrefixNavigation'),
        );
        setProps({
          isRedirectingToCalendar: true,
        });
        return;
      }

      const instance = appParams.instance as string;

      const isSEO = wixSdkAdapter.isSEO();
      const isBookingCalendarInstalled =
        await wixSdkAdapter.isBookingCalendarInstalled();
      const isBookingFormInstalled =
        await wixSdkAdapter.isBookingFormInstalled();
      const { isPreview, isSSR, isEditor, isViewer } = flowAPI.environment;
      let scheduleViewModel: SchedulingSectionViewModel = {
        status: SchedulingSectionStatus.LOADING,
        isBookable: false,
      };
      let locationViewModel, getServiceSchedulingDataByLocation;
      let timezoneViewModel: SchedulingTimezoneViewModel;
      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};

      async function fetchWidgetConfigFromServer(serviceSlug) {
        return (
          await httpClient.request({
            ...getConfig(serviceSlug, isPreview),
            headers: { 'x-time-budget': '10000' },
          })
        ).data;
      }

      const initSlotIntegration = (serviceId?: string) => {
        const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
        const slot1$w = slotAPIFactory.getSlotAPI('slot1');
        if (serviceId) {
          slot1$w.bookingsServiceId = serviceId;
        }
      };

      const initWidget = async () => {
        if (isViewer || isPreview) {
          const serviceSlug = await wixSdkAdapter.getServiceSlug(
            SERVICE_PAGE_NAME,
            isAppReflowGetServiceSlugFixEnable,
          );

          const config: WidgetConfig = await fetchWidgetConfigFromServer(
            serviceSlug,
          );

          service = config.SEO.serviceResponse?.service;

          const referralInfo = wixSdkAdapter.getUrlQueryParamValue(
            BookingsQueryParams.REFERRAL,
          );
          flowAPI.bi &&
            flowAPI.bi.updateDefaults({
              ...biDefaults,
              ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
              serviceId: config.serviceDto?.id,
              service_id: config.serviceDto?.id,
              type: config.serviceDto?.type,
              referralInfo,
            });

          if (!config.serviceDto) {
            setProps({ fitToContentHeight: true });
            return;
          }

          if (config.SEO.serviceResponse) {
            await wixCodeApi.seo.renderSEOTags({
              itemType: ITEM_TYPES.BOOKINGS_SERVICE,
              itemData: {
                serviceResponse: config.SEO.serviceResponse,
                bookingsPolicyDto: config.bookingPolicyDto,
              },
              seoData: config.SEO.serviceResponse?.service?.advancedSeoData,
            });
          }

          const isFormOOINavigationEnabled = experiments.enabled(
            'specs.bookings.ServicePageFormOOINavigation',
          );
          const isAnywherePublicDataOverridesEnabled = experiments.enabled(
            'specs.bookings.anywherePublicDataOverrides',
          );
          const isBookingsAnywhereUoUEmailEnabled = experiments.enabled(
            'specs.bookings.BookingsAnywhereUoUEmail',
          );

          const isCourse = config.serviceDto?.type === ServiceType.COURSE;
          const serviceSchedule = config.SEO.serviceResponse?.schedules?.find(
            (schedule) => schedule.status === ScheduleStatus.CREATED,
          );
          const firstSessionStart = serviceSchedule?.firstSessionStart;
          const lastSessionEnd = serviceSchedule?.lastSessionEnd;
          const queryLocationId = wixCodeApi.location.query?.location;
          const selectedLocation = config.serviceDto?.info.locations?.find(
            (serviceLocation) =>
              serviceLocation.businessLocation?.id === queryLocationId,
          )
            ? queryLocationId
            : undefined;
          const initViewModels = initializeViewModels({
            config,
            t,
            experiments,
            selectedLocation,
            isSEO,
            isBookingCalendarInstalled,
            settings,
          });
          viewModel = initViewModels.viewModel;
          locationViewModel = initViewModels.locationViewModel;
          timezoneViewModel = initViewModels.timezoneViewModel;

          const getActionName = async () => {
            if (isCourse) {
              return isBookingFormInstalled
                ? ACTION_NAMES.NAVIGATE_TO_BOOKING_FORM
                : ACTION_NAMES.NAVIGATE_TO_CONTACT_FORM;
            } else {
              return isBookingCalendarInstalled
                ? ACTION_NAMES.NAVIGATE_TO_CALENDAR
                : undefined;
            }
          };

          navigateToCalendar = async (initiatedBy?: string) => {
            flowAPI.bi &&
              flowAPI.bi.report(
                bookingsBookItClick({
                  section: initiatedBy,
                  actionName: await getActionName(),
                  businessId: undefined,
                }),
              );

            const isDayfulMovePremiumBlockModalEnabled = experiments.enabled(
              'specs.bookings.DayfulMovePremiumBlockModal',
            );
            const isRouterPrefixNavigationEnable = experiments.enabled(
              'specs.bookings.RouterPrefixNavigation',
            );
            const shouldShowPremiumModalInDayfulFlow =
              !isDayfulMovePremiumBlockModalEnabled &&
              (await isAnywhereFlow(wixCodeApi, experiments, isPreview));
            return handleNavigation({
              config,
              isPreview,
              wixSdkAdapter,
              locationId: locationViewModel.currentLocation,
              timezone: timezoneViewModel.viewTimezone,
              onNavigationFailed: ({ failReasons }) => {
                if (shouldShowPremiumModalInDayfulFlow) {
                  isBookingsAnywhereUoUEmailEnabled &&
                    sendNoPaymentNotification(flowAPI.httpClient, failReasons);
                } else {
                  httpClient.request(
                    sendNotification(failReasons, config.serviceDto?.id),
                  );
                }
                flowAPI.bi &&
                  flowAPI.bi.report(
                    bookingsCantBookGroupsMessage({
                      widget_name: 'service_page',
                      referralInfo: 'service_page',
                      isPreview,
                      failReason: JSON.stringify(failReasons),
                    }),
                  );
                showUserMessage();
              },
              isFormOOINavigationEnabled,
              isAnywherePublicDataOverridesEnabled,
              isDayful: shouldShowPremiumModalInDayfulFlow,
              isRouterPrefixNavigationEnable,
            });
          };

          getServiceSchedulingDataByLocation = (locationId: string) => {
            locationViewModel = schedulingLocationViewModelFactory({
              serviceInfoDto: config.serviceDto!.info,
              selectedLocation: locationId,
              t,
            });
            scheduleViewModel = {
              status: SchedulingSectionStatus.LOADING,
              isBookable: viewModel.body.isBookable,
            };
            setProps({
              locationViewModel,
              scheduleViewModel,
              fitToContentHeight: true,
            });
            getServiceSchedulingData({
              config,
              settings,
              httpClient,
              instance,
              locationId,
              isIncreaseServicePageTimeBudget: true,
            })
              .then((schedule) => {
                const changeTimezoneCallback = (timezoneType: TimezoneType) => {
                  timezoneViewModel = schedulingTimezoneViewModelFactory({
                    businessInfo: config.businessInfo,
                    selectedTimezoneType: timezoneType,
                    isBookingCalendarInstalled,
                  });
                  viewModel = servicePageViewModelFactory({
                    config,
                    t,
                    experiments,
                    viewTimezone: timezoneViewModel.viewTimezone,
                    isSEO,
                    settings,
                  });
                  scheduleViewModel = schedulingSectionViewModelFactory({
                    isBookable: viewModel.body.isBookable,
                    catalogSessionsDto: schedule?.sessions,
                    businessInfo: config?.businessInfo,
                    viewTimezone: timezoneViewModel.viewTimezone,
                    isCourse,
                    firstSessionStart,
                    lastSessionEnd,
                    t,
                  });
                  setProps({
                    viewModel,
                    timezoneViewModel,
                    scheduleViewModel,
                  });
                };
                scheduleViewModel = schedulingSectionViewModelFactory({
                  isBookable: viewModel.body.isBookable,
                  catalogSessionsDto: schedule?.sessions,
                  businessInfo: config?.businessInfo,
                  viewTimezone: timezoneViewModel.viewTimezone,
                  isCourse,
                  firstSessionStart,
                  lastSessionEnd,
                  t,
                });
                setProps({
                  locationViewModel,
                  scheduleViewModel,
                  timezoneViewModel,
                  changeTimezoneCallback,
                });
              })
              .catch((e) => {
                reportError(e);
                scheduleViewModel = {
                  status: SchedulingSectionStatus.FAILED,
                  isBookable: false,
                };
                setProps({
                  scheduleViewModel,
                });
              });
          };

          if (!isSSR) {
            getServiceSchedulingDataByLocation(
              locationViewModel.currentLocation,
            );
          }

          const trackingInfo = getTrackingInfoForServicePageLoads({
            service: config.serviceDto,
            businessName: config.businessInfo.name || '',
          });
          wixSdkAdapter.trackAnalytics(trackingInfo);
        } else {
          isEditorX =
            flowAPI.controllerConfig.config.style.styleParams.booleans
              .responsive;
          const courseAvailability =
            (settings.courseAvailability &&
              (settings.displayNumberOfSpots
                ? CourseAvailabilityDisplay.NUMBER_OF_SPOTS
                : CourseAvailabilityDisplay.AVAILABILITY)) ||
            undefined;
          viewModel = dummyViewModelFactory({
            t,
            isEditorX,
            courseAvailability,
          });
          const dummyBusinessInfo = {
            timeZone: 'UTC',
            regionalSettingsLocale: flowAPI.environment.language,
            dateRegionalSettingsLocale: flowAPI.environment.language,
          };
          scheduleViewModel = dummySchedulingViewModel({
            experiments,
            t,
            businessInfo: dummyBusinessInfo,
            scheduleDays: settings.scheduleDays,
          });

          flowAPI.bi &&
            flowAPI.bi.updateDefaults({
              ...biDefaults,
              ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
            });
        }

        const { userMessage, showUserMessage } = initUserMessage(setProps);

        setProps({
          navigateToCalendar,
          scheduleViewModel,
          locationViewModel,
          changeLocationCallback: getServiceSchedulingDataByLocation,
          viewModel,
          userMessage,
          fitToContentHeight: true,
        });
      };
      await initWidget();

      wixCodeApi.location.onChange(async () => {
        await initWidget();
      });

      const isAddSlotPlaceholderOnServicePageEnabled = experiments.enabled(
        'specs.bookings.addSlotPlaceholderOnServicePage',
      );
      if (isAddSlotPlaceholderOnServicePageEnabled) {
        initSlotIntegration(service?.id);
      }
    },
    exports() {
      return {
        onNextClicked(overrideCallback) {
          wixSdkAdapter.navigateToBookingsCalendarPage = () =>
            overrideCallback({ service });
          wixSdkAdapter.navigateToBookingsFormPage = () =>
            overrideCallback({ service });
          wixSdkAdapter.navigateToBookingsBookAction = () =>
            overrideCallback({ service });
        },
      };
    },
    updateConfig($w, newConfig) {
      const updatedPublicData = newConfig.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        settingsParams,
      );
      if (settings.scheduleDays !== updatedSettings.scheduleDays) {
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.environment.language,
        };

        const scheduleViewModel = dummySchedulingViewModel({
          experiments,
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: updatedSettings.scheduleDays,
        });

        setProps({
          scheduleViewModel,
        });
      }
      if (
        updatedSettings.displayNumberOfSpots !==
        prevSettings.displayNumberOfSpots
      ) {
        const courseAvailability =
          (updatedSettings.courseAvailability &&
            (updatedSettings.displayNumberOfSpots
              ? CourseAvailabilityDisplay.NUMBER_OF_SPOTS
              : CourseAvailabilityDisplay.AVAILABILITY)) ||
          undefined;
        const viewModel = dummyViewModelFactory({
          t,
          isEditorX,
          courseAvailability,
        });
        setProps({
          viewModel,
        });
      }
      prevSettings = updatedSettings;
    },
  };
};

export default createController;
